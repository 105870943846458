<template>
  <div class="page-login bg-cover">
    <router-link class="logo" to="/">
      <img src="@/assets/images/logo.png" alt="">
    </router-link>
    <div class="text s56 font-zihun color-f">
      <p>选择易筑</p>
      <p>让工程建筑更容易</p>
    </div>
    <div class="md-login c-card">
      <div class="tabs flex-center s18 color-2 font-medium">
        <div class="tab-item">注册账号</div>
      </div>
      <form class="form s16 color-9" @submit.prevent="handleSubmit">
        <!-- 手机号 -->
        <div class="form-item form-item--ipt">
          <div class="text_input">
            <img src="@/assets/images/login-acount.png" alt="" class="ipt-icon">
            <input type="text" class="ipt" placeholder="请输入手机号" v-model="form.username">
          </div>
          <div class="text_tip" v-if="showTextTip">请输入手机号</div>
        </div>
        <!-- 密码 -->
        <div class="form-item form-item--ipt form-item--psd">
          <div class="password_input">
            <img src="@/assets/images/login-psd.png" alt="" class="ipt-icon">
            <input :type="psd ? 'password' : 'text'" class="ipt" placeholder="请输入登录密码" v-model="form.password">
            <div class="icon-psd bg-contain" @click="tpgglePsd()"></div>
          </div>
          <div class="text_tip" v-if="showPasswordTip">请输入登录密码</div>
        </div>
        <!-- 验证码 -->
        <div class="form-item form-item--ipt form-item--code">
          <div class="text_input">
            <img src="@/assets/images/login-valid.png" alt="" class="ipt-icon">
            <input type="text" class="ipt" placeholder="请输入验证码" v-model="form.code">
            <BaseButton style-type='light' class="btn-code" @click.native="sendCode">{{ sendCodeBtnText }}</BaseButton>
          </div>
          <div class="text_tip" v-if="showCodeTip">请输入验证码</div>
        </div>
        <!-- 请输入邀请码（选填） -->
        <div class="form-item form-item--ipt">
          <img src="@/assets/images/login-code.png" alt="" class="ipt-icon">
          <input type="text" class="ipt" placeholder="请输入邀请码（选填）" v-model="form.shareCode">
        </div>
        <!-- 注册 -->
        <div class="form-item">
          <BaseButton size='large' class="btn-submit" tag='button'>注册</BaseButton>
        </div>
        <!-- 同意协议 -->
        <div class="form-item form-item--2">
          <BaseSwitch v-model="agree">
            已阅读并同意
            <!-- <router-link to="/agreement">《用户协议》</router-link>
            <router-link to="/agreement">《隐私政策》</router-link> -->
            <span @click="jumpAgreement">《用户协议》</span>
            <span @click="jumpAgreement">《隐私政策》</span>
          </BaseSwitch>
        </div>
        <div class="form-item form-item--2" style="color: #0174da;cursor: pointer;" @click="goLogin">
          账号已存在.前往登录
        </div>
      </form>
      <BaseDialog :visible.sync="visible" width="5.36rem" :cancel="false" :confirm="false">
        <div class='authDialog'>
          <div class="tit s22 color-2 font-medium">当前还未注册认证，是否前往认证身份?</div>
          <BaseButton class="btn" size="large" tag='router-link' to='/auth'>去认证</BaseButton>
          <BaseButton class="btn" size="large" style-type='light' @click="handleCancel">暂不认证</BaseButton>
        </div>
      </BaseDialog>
    </div>
  </div>
</template>

<script>
import { sendSmsApi, registerApi, ProtocolsApi } from "@/request/api/login.js";
export default {
  data() {
    return {
      loginType: 1,
      remember: false,
      agree: false,
      areaCode: 86,
      options: [
        { value: 86, name: '+86' },
        { value: 886, name: '+886' },
        { value: 852, name: '+852' },
        { value: 853, name: '+853' },
      ],
      sendCodeBtnText: '发送验证码',
      isSendCode: false,
      timer: '',
      psd: true,
      visible: false,
      agree: false,
      showPasswordTip: false,
      showTextTip: false,
      showCodeTip: false,
      form: {
        username: "",
        code: "",
        password: "",
        shareCode: ""
      },
      time: 0
    };
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    "form.username"(val) {
      if (val) {
        this.showTextTip = false;
      }
    },
    "form.password"(val) {
      if (val) {
        this.showPasswordTip = false;
      }
    },
    "form.code"(val) {
      if (val) {
        this.showCodeTip = false;
      }
    }
  },
  methods: {
    goLogin() {
      this.$router.push('/login');
    },
    jumpAgreement() {
      ProtocolsApi().then(({ status, data }) => {
        if (status == 200) {
          window.open(data.reg, '_blank')
        }
      })
    },
    handleCancel() {
      this.visible = false
    },
    //校验
    validate() {
      if (!this.form.username && !this.form.password) {
        this.showTextTip = true;
        this.showPasswordTip = true;
        return false
      }
      if (!this.form.username) {
        this.showTextTip = true;
        return false
      }
      if (!this.form.password) {
        this.showPasswordTip = true;
        return false
      }

      if (!this.form.code) {
        this.showCodeTip = true;
        return false
      }
      if (!this.agree) {
        this.$message.warning("请勾选协议");
        return false
      }
      return true
    },

    handleSubmit() {
      if (!this.validate()) return;
      registerApi({ ...this.form }).then(res => {
        if (res.status == 200) {
          this.$message.success("注册成功");
          setTimeout(() => {
            localStorage.setItem("token", res.data.token);
            this.$router.push("/login");
          }, 2000)
        }
      })
    },
    //发送验证码
    sendCode() {
      if (this.time == 0) {
        // this.time = 60;
        if (!this.form.username) {
          this.showTextTip = true;
          return
        }
        if (!this.agree) {
          this.$message.warning("请勾选协议");
          return false
        }
        let params = {
          type: 1,
          mobile: this.form.username
        }
        sendSmsApi(params).then(res => {
          if (res.status == 200) {
            this.$message.success("验证码已发送");
            this.time = 60;
            this.timer = setInterval(() => {
              if (this.time == 0) {
                clearInterval(this.timer)
                this.sendCodeBtnText = '发送验证码';
              } else {
                this.sendCodeBtnText = this.time + '秒后重试'
                this.time--;
              }
            }, 1000)
          }
        })
      }
    },
    tpgglePsd() {
      this.psd = !this.psd
    },
  }
};
</script>

<style lang="scss" scoped>
.page-login {
  min-height: 100vh;
  background-image: url(~@/assets/images/login-bg.jpg);
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.6rem;

  .logo {
    position: absolute;
    left: 1.6rem;
    top: .18rem;
    font-size: 0;

    img {
      width: 1.1rem;
    }
  }

  .text {
    position: absolute;
    left: 1.6rem;
    top: 45.8%;
    transform: translateY(-50%);
    line-height: calc(76 / 56 * 1em);
    text-shadow: 2px 2px 2px rgba($color: #00c6ed, $alpha: .1);
  }
}

.md-login {
  width: 5.33rem;
  margin-left: auto;
  padding: .78rem .59rem .48rem;
  background: rgba($color: #fff, $alpha: .95);

  .tabs {
    .tab-item {
      margin-right: .2rem;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }

      &:after {
        display: block;
        content: '';
        width: .2rem;
        height: .04rem;
        background-color: $green;
        border-radius: .02rem;
        margin: .06rem auto 0;
      }
    }
  }

  .form {
    margin-top: .3rem;

    .ipt-icon {
      width: .2rem;
    }

    .ipt {
      width: 100%;
      height: .5rem;
      background-color: #ffffff;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      color: #222;
      transition: .2s;
      padding: 0 .1rem;

      &::placeholder {
        color: #999;
      }

      &:focus {
        border-color: $blue;
      }
    }

    .text_input,
    .password_input {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
    }

    .text_tip {
      color: red;
    }

    &-item {
      margin-bottom: .2rem;

      &--ipt {
        position: relative;

        .ipt-icon {
          position: absolute;
          left: .11rem;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        .ipt {
          padding-left: .36rem;
        }
      }

      &--1 {
        margin-top: -.02rem;
        margin-bottom: .17rem;
      }

      &--2 {
        margin-top: -.04rem;

        a:hover {
          color: $blue;
        }
      }

      &--phone {
        display: flex;

        ::v-deep .area-code {
          height: .5rem;
          width: 0.7rem;
          background-color: #fff;
          border-radius: .04rem 0 0 .04rem;
          border: solid 1px #cae1ec;
          border-right: none;
          color: #444;
        }

        .ipt {
          border-radius: 0 .04rem .04rem 0;
        }
      }

      &--code {
        .ipt {
          padding-right: 1.2rem;
        }

        .btn-code {
          position: absolute;
          right: .04rem;
          top: 0.04rem;
          bottom: .04rem;
          margin: auto;
          min-width: 1.1rem
        }
      }

      &--psd {
        .ipt {
          padding-right: .5rem;
        }

        .icon-psd {
          position: absolute;
          right: .18rem;
          top: 0;
          bottom: 0;
          margin: auto;
          width: .2rem;
          height: .2rem;
          cursor: pointer;
        }

        input[type=password]+.icon-psd {
          background-image: url(~@/assets/images/icon-psd-1.png);
        }

        input[type=text]+.icon-psd {
          background-image: url(~@/assets/images/icon-psd-2.png);
        }
      }
    }

    .btn-submit {
      width: 100%;
    }
  }
}

.authDialog {
  padding: .78rem .6rem .8rem;
  text-align: center;

  .tit {
    margin-bottom: .06rem;
  }

  .base-button {
    width: 100%;
    margin-top: .2rem;
  }
}
</style>